import Link from "next/dist/client/link";
import Image from "next/dist/client/image";
import ListingTagsList from "./ListingTagsList";

const JobMiniListing = ({
  slug,
  title,
  date_created,
  city,
  status,
  views,
  job_applications,
  company_logo,
}) => {
  return (
    <Link href={"/is-ilanlari/" + slug} locale="tr">
      <a className="flex items-center gap-4 px-6 py-6 transition-colors duration-100 ease-out border border-gray-200 rounded-md cursor-pointer sm:gap-6 hover:border-primaryLight">
        <div className="block w-16 h-16 p-2 rounded-md shadow-md">
          <Image
            width={60}
            height={60}
            layout="responsive"
            objectFit="contain"
            objectPosition="center"
            alt={title}
            src={`${process.env.NEXT_PUBLIC_IMAGE_URL}${company_logo}`}
          />
        </div>
        <div>
          <div>
            <ListingTagsList
              category={city}
              date={date_created}
              locale={"tr"}
              type={status === "published" ? "İŞ İLANI" : "KAPANMIŞ"}
            />
          </div>
          <div className="mt-1 text-lg font-semibold">{title}</div>
          <div className="flex flex-wrap items-center gap-2">
            <div className="text-sm text-neutral-400">{views} görüntüleme.</div>
            <div className="text-sm text-neutral-400">
              {job_applications === 0
                ? "Başvuru Yok"
                : "Başvuru: " + job_applications}
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default JobMiniListing;
