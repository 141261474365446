import Image from "next/image";
import Link from "next/link";

const CompanyLogoSliderItem = ({ company, aboveFold }) => {
  return (
    <Link href={"/tarim-sirketleri/" + company.slug}>
      <a className="flex flex-col items-center snap-start">
        <div className="relative w-12 h-12 overflow-hidden rounded-md sm:w-24 sm:h-24">
          <Image
            layout="fill"
            objectFit="contain"
            objectPosition="center"
            alt={company.title}
            src={`${process.env.NEXT_PUBLIC_IMAGE_URL}${company.slider_image}?key=web-p`}
            blurDataURL={`${process.env.NEXT_PUBLIC_IMAGE_URL}${company.slider_image}?key=blurry-thumbnail`}
            priority={aboveFold}
          />
        </div>
        <div className="mt-2 text-xs truncate">{company.title}</div>
      </a>
    </Link>
  );
};

export default CompanyLogoSliderItem;
