import React from "react";
import PostMainListing from "../listings/PostMainListing";
import PostMiniListing from "../listings/PostMiniListing";
import ProductMiniListing from "../listings/ProductMiniListing";
import ControlMiniListing from "../listings/ControlMiniListing";
import GuideMainListing from "../listings/GuideMainListing";

// eslint-disable-next-line react/display-name
const FeedListThree = React.memo(({ list }) => {
  const postsFirst = list?.filter(
    (c, index) => c.contentType === "post" && index < 2
  );
  const postsSecond = list?.filter(
    (c, index) => c.contentType === "post" && index > 2
  );
  const products = list?.filter((c) => c.contentType === "product");
  const controls = list?.filter((c) => c.contentType === "control");
  const guides = list?.filter((c) => c.contentType === "guide");

  return (
    <div className="flex flex-col gap-8">
      {/* Controls */}
      {controls?.length >= 1 && (
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
          {controls?.map((control) => {
            return (
              <ControlMiniListing
                slug={control.slug}
                type={control.type}
                title={control.title}
                image={control.image}
                category={control.category.title}
                key={(Math.random() * 100).toString()}
              />
            );
          })}
        </div>
      )}
      {/* Posts First */}
      {postsFirst?.map((post, index) => {
        if (index === 1) {
          return (
            <PostMainListing
              key={post.id}
              title={post.title}
              description={post.description}
              image={post.image}
              link={"/yazilar/" + post.slug}
              date={post.date_created}
              author={
                post.type === "company"
                  ? {
                      title: post.company.title,
                      avatar: post.company.company_logo,
                      city: post.company.city,
                      country: post.company.country,
                    }
                  : post.author
              }
              authorType={post.type}
              category={post.tags.reduce((acc, tag, index) => {
                if (index === 0) {
                  return tag.categories_id.title;
                } else {
                  return acc;
                }
              }, "")}
              likeCount={post.like_count}
              commentCount={post.comments.length}
              locale={"tr"}
            />
          );
        } else {
          return (
            <PostMiniListing
              title={post.title}
              slug={post.slug}
              category={post.tags.reduce((acc, tag, index) => {
                if (index === 0) {
                  return tag.categories_id.title;
                } else {
                  return acc;
                }
              }, "")}
              image={post.image}
              locale="tr"
              date={post.date_created}
              key={post.id}
              description={post.description}
            />
          );
        }
      })}
      {/* Posts Second */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        {products?.map((product) => {
          return (
            <ProductMiniListing
              key={product.id}
              id={product.id}
              title={product.title}
              slug={product.slug}
              subcategory={product.subcategory.title}
              company={product.company.title}
              companyID={product.company.id}
              image={product.mini_image}
              score={1}
              companyScore={1}
            />
          );
        })}
      </div>
      {/* Posts Second */}
      {postsSecond?.map((post) => {
        return (
          <PostMiniListing
            title={post.title}
            slug={post.slug}
            category={post.tags.reduce((acc, tag, index) => {
              if (index === 0) {
                return tag.categories_id.title;
              } else {
                return acc;
              }
            }, "")}
            image={post.image}
            locale="tr"
            date={post.date_created}
            key={post.id}
            description={post.description}
          />
        );
      })}
      {/* Guides */}

      {guides?.map((guide) => {
        return (
          <GuideMainListing
            key={guide.id}
            title={guide.title}
            link={guide.slug}
            date={guide.date_created}
            farming_type={guide.farming_type}
            image={guide.image}
            description={guide.description}
            locale={"tr"}
            likeCount={guide.like_count}
            commentCount={guide.post_comments.length}
          />
        );
      })}
    </div>
  );
});

export default FeedListThree;
