import Link from "next/link";
import React from "react";
import MiniIconGreen from "../../elements/brand/MiniIconGreen";
import PrimaryGreenButton from "../../elements/buttons/PrimaryGreenButton";
import {
  YoutubeFill,
  FacebookFill,
  InstagramFill,
  LinkedInV2Fill,
} from "akar-icons";
export const SignupMessage = () => {
  return (
    <div className="flex flex-col items-center justify-center px-4 py-10 bg-emerald-100 bg-opacity-50 rounded-md">
      <MiniIconGreen size="medium" />
      <h6 className="mt-4 mb-6 text-center font-medium max-w-sm">
        hortiturkey sana fayda sağlıyor mu? Saniyeler içinde üye ol ve aldığın
        hizmetin kalitesini arttır.
      </h6>
      <PrimaryGreenButton size="large" type="link" href="/kayit">
        Ücretsiz Üye Ol
      </PrimaryGreenButton>
    </div>
  );
};
export const SocialMediaMessage = () => {
  return (
    <div className="flex flex-col items-center justify-center px-4 py-10 border border-gray-200 rounded-md">
      <MiniIconGreen size="medium" />
      <h6 className="mt-4 mb-6 text-center font-medium max-w-[280px]">
        Bizi takip ediyor musun? Aramıza katıl ve hiçbir şeyi kaçırma!
      </h6>
      <div className="flex items-center flex-wrap justify-center gap-4">
        <Link href="https://www.youtube.com/channel/UCqINrGV1W4KY00N3k87O2EQ">
          <a className="bg-neutral-800 flex items-center gap-2  rounded-md py-2 px-3 text-white">
            <YoutubeFill strokeWidth={2} size={18} />
            Youtube
          </a>
        </Link>
        <Link href="https://www.instagram.com/hortiturkey">
          <a className="bg-neutral-800 flex items-center gap-2  rounded-md py-2 px-3 text-white">
            <InstagramFill strokeWidth={2} size={18} />
            Instagram
          </a>
        </Link>
        <Link href="https://www.facebook.com/hortiturkey">
          <a className="bg-neutral-800 flex items-center gap-2  rounded-md py-2 px-3 text-white">
            <FacebookFill strokeWidth={2} size={18} />
            Facebook
          </a>
        </Link>
        <Link href="https://www.linkedin.com/company/hortiturkey">
          <a className="bg-neutral-800 flex items-center gap-2  rounded-md py-2 px-3 text-white">
            <LinkedInV2Fill strokeWidth={2} size={18} />
            Linkedin
          </a>
        </Link>
      </div>
    </div>
  );
};
