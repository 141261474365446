import Link from "next/dist/client/link";
import Image from "next/image";
import ImpressionObserver from "../observers/ImpressionObserver";

const ProductMiniListing = ({
  title,
  image,
  subcategory,
  company,
  companyID,
  score,
  companyScore,
  id,
  slug,
}) => {
  return (
    <>
      <ImpressionObserver
        id={id}
        score={score}
        companyScore={companyScore}
        company={companyID}
        title={title}
      >
        <Link href={"/urunler/" + slug}>
          <a
            data-type="product"
            data-product={title}
            data-id={id}
            className="flex items-center h-full py-2 pl-3 pr-8 transition-colors duration-100 ease-out border border-gray-200 rounded-md cursor-pointer hover:border-primaryLight"
          >
            <div className="block w-20 overflow-hidden rounded-md">
              <Image
                width={60}
                height={60}
                layout="responsive"
                objectPosition="center"
                objectFit="cover"
                alt={title}
                src={`${process.env.NEXT_PUBLIC_IMAGE_URL}${image}?key=product-listing-mini`}
              />
            </div>
            <div className="flex flex-col ml-4 truncate">
              <span className="text-sm text-neutral-600">{subcategory}</span>
              <span className="font-semibold">{title}</span>
              <span className="text-sm text-neutral-400">{company}</span>
            </div>
          </a>
        </Link>
      </ImpressionObserver>
    </>
  );
};

export default ProductMiniListing;
