import RegularContainer from "../../layouts/RegularContainer";
import PaddingContainer from "../../layouts/PaddingContainer";
import ThreeColumnLayout from "../../layouts/ThreeColumnLayout";
import dynamic from "next/dynamic";
const SidebarMenu = dynamic(() => import("../sidebarMenu/SidebarMenu"));
const SidebarMenuItem = dynamic(() => import("../sidebarMenu/SidebarMenuItem"));
import { Book, Bug, Diamond, Globe, Home, Leaf, Telescope } from "akar-icons";
import PostMiniListing from "../listings/PostMiniListing";
import CompanyLogoSlider from "./CompanyLogoSlider";
import Feed from "../feed/Feed";

const HomeTR = ({ sliderData, initialFeedData, popularPostsData }) => {
  return (
    <>
      <PaddingContainer>
        <RegularContainer>
          <CompanyLogoSlider data={sliderData} />
          <ThreeColumnLayout>
            {/* SidebarMenu */}
            <div className="hidden sm:block bg-gray-50 sm:bg-transparent sm:border-none border-t-primaryLight sm:relative sm:z-auto sm:order-none lg:col-span-2">
              <div className="sticky top-24">
                <SidebarMenu>
                  <SidebarMenuItem handler={() => {}} active={true}>
                    <Home size={18} />
                    <span>Akış</span>
                  </SidebarMenuItem>
                  <SidebarMenuItem type="link" link="/tarim-urunleri">
                    <Leaf size={18} />
                    <span>Ürünler</span>
                  </SidebarMenuItem>
                  <SidebarMenuItem type="link" link="/tarim-sirketleri">
                    <Diamond size={18} />
                    <span>Şirketler</span>
                  </SidebarMenuItem>
                  <SidebarMenuItem type="link" link="/is-ilanlari">
                    <Telescope size={18} />
                    <span>İş İlanları</span>
                  </SidebarMenuItem>
                  <SidebarMenuItem
                    type="link"
                    link="/zirai-mucadele-kutuphanesi"
                  >
                    <Bug size={18} />
                    <span>Mücadele</span>
                  </SidebarMenuItem>
                  <SidebarMenuItem
                    type="link"
                    link="/yetistiricilik-kutuphanesi"
                  >
                    <Book size={18} />
                    <span>Yetiştiricilik</span>
                  </SidebarMenuItem>
                  <SidebarMenuItem type="link" link="/tarim-fuarlari-takvimi">
                    <Globe size={18} />
                    <span>Fuarlar</span>
                  </SidebarMenuItem>
                </SidebarMenu>
              </div>
            </div>
            {/* Content */}
            <div className="col-span-full sm:col-span-3 lg:col-span-7">
              <Feed initialFeedData={initialFeedData} />
            </div>
            {/* Popular Posts */}
            <div className="relative hidden col-span-3 lg:block">
              <div className="sticky top-24">
                <div className="items-center hidden mb-6 sm:flex">
                  <span className="mr-4 font-medium ">ÇOK OKUNANLAR</span>
                  <span className="flex-1 border-b border-b-gray-200"></span>
                </div>
                <div className="flex flex-col gap-4">
                  {popularPostsData.map((post) => (
                    <PostMiniListing
                      title={post.title}
                      slug={post.slug}
                      category={post.tags.reduce((acc, tag, index) => {
                        if (index === 0) {
                          return tag.categories_id.title;
                        } else {
                          return acc;
                        }
                      }, "")}
                      locale="tr"
                      date={post.date_created}
                      key={post.id}
                      type={"popular"}
                    />
                  ))}
                </div>
              </div>
            </div>
          </ThreeColumnLayout>
        </RegularContainer>
      </PaddingContainer>
    </>
  );
};

export default HomeTR;
